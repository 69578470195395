import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Documents = () => import('@/entities/documents/documents.vue');
const DocumentsUpdate = () => import('@/entities/documents/documents-update.vue');
const DocumentsUpdateNew = () => import('@/entities/documents/documents-update-new.vue');
const DocumentsDetails = () => import('@/entities/documents/documents-details.vue');

const Packages = () => import('@/entities/packages/packages.vue');
const PackagesManage = () => import('@/entities/packages/packages-manage.vue');
const PackagesManageStages = () => import('@/entities/packages/packages-manage-stages.vue');
const PackagesManageStagesDocument = () => import('@/entities/packages/packages-manage-stages-documents.vue');
const PackagesUpdate = () => import('@/entities/packages/packages-update.vue');
const PackagesDetails = () => import('@/entities/packages/packages-details.vue');

const Documentcategories = () => import('@/entities/documentcategories/documentcategories.vue');
const DocumentcategoriesUpdate = () => import('@/entities/documentcategories/documentcategories-update.vue');
const DocumentcategoriesDetails = () => import('@/entities/documentcategories/documentcategories-details.vue');

const Documentinstances = () => import('@/entities/documentinstances/documentinstances.vue');
const DocumentinstancesUpdate = () => import('@/entities/documentinstances/documentinstances-update.vue');
const DocumentinstancesDetails = () => import('@/entities/documentinstances/documentinstances-details.vue');

const Documentfields = () => import('@/entities/documentfields/documentfields.vue');
const DocumentfieldsUpdate = () => import('@/entities/documentfields/documentfields-update.vue');
const DocumentfieldsDetails = () => import('@/entities/documentfields/documentfields-details.vue');

const Fields = () => import('@/entities/fields/fields.vue');
const FieldsUpdate = () => import('@/entities/fields/fields-update.vue');
const FieldsDetails = () => import('@/entities/fields/fields-details.vue');

const Stages = () => import('@/entities/stages/stages.vue');
const StagesUpdate = () => import('@/entities/stages/stages-update.vue');
const StagesDetails = () => import('@/entities/stages/stages-details.vue');

const Stageinstances = () => import('@/entities/stageinstances/stageinstances.vue');
const StageinstancesUpdate = () => import('@/entities/stageinstances/stageinstances-update.vue');
const StageinstancesDetails = () => import('@/entities/stageinstances/stageinstances-details.vue');

const Packagestages = () => import('@/entities/packagestages/packagestages.vue');
const PackagestagesUpdate = () => import('@/entities/packagestages/packagestages-update.vue');
const PackagestagesDetails = () => import('@/entities/packagestages/packagestages-details.vue');

const Packageinstances = () => import('@/entities/packageinstances/packageinstances.vue');
const PackageinstancesClient = () => import('@/entities/packageinstances/packageinstances-clients.vue');
const PackageinstancesUpdate = () => import('@/entities/packageinstances/packageinstances-update.vue');
const PackageinstancesDetails = () => import('@/entities/packageinstances/packageinstances-details.vue');

const UnicornUsers = () => import('@/entities/unicorn-users/unicorn-users.vue');
const UnicornUsersUpdate = () => import('@/entities/unicorn-users/unicorn-users-update.vue');
const UnicornUsersDetails = () => import('@/entities/unicorn-users/unicorn-users-details.vue');

const ClientFields = () => import('@/entities/client-fields/client-fields.vue');
const ClientFieldsUpdate = () => import('@/entities/client-fields/client-fields-update.vue');
const ClientFieldsDetails = () => import('@/entities/client-fields/client-fields-details.vue');

const ClientNotes = () => import('@/entities/client-notes/client-notes.vue');
const ClientNotesUpdate = () => import('@/entities/client-notes/client-notes-update.vue');
const ClientNotesDetails = () => import('@/entities/client-notes/client-notes-details.vue');

const ClientTaskInstances = () => import('@/entities/client-task-instances/client-task-instances.vue');
const ClientTaskInstancesUpdate = () => import('@/entities/client-task-instances/client-task-instances-update.vue');
const ClientTaskInstancesDetails = () => import('@/entities/client-task-instances/client-task-instances-details.vue');

const ClientTasks = () => import('@/entities/client-tasks/client-tasks.vue');
const ClientTasksUpdate = () => import('@/entities/client-tasks/client-tasks-update.vue');
const ClientTasksDetails = () => import('@/entities/client-tasks/client-tasks-details.vue');

const CustomClientTasks = () => import('@/entities/custom-client-tasks/custom-client-tasks.vue');
const CustomClientTasksUpdate = () => import('@/entities/custom-client-tasks/custom-client-tasks-update.vue');
const CustomClientTasksDetails = () => import('@/entities/custom-client-tasks/custom-client-tasks-details.vue');

const CustomStaffTasks = () => import('@/entities/custom-staff-tasks/custom-staff-tasks.vue');
const CustomStaffTasksUpdate = () => import('@/entities/custom-staff-tasks/custom-staff-tasks-update.vue');
const CustomStaffTasksDetails = () => import('@/entities/custom-staff-tasks/custom-staff-tasks-details.vue');

const PlaceHolders = () => import('@/entities/place-holders/place-holders.vue');
const PlaceHoldersUpdate = () => import('@/entities/place-holders/place-holders-update.vue');
const PlaceHoldersDetails = () => import('@/entities/place-holders/place-holders-details.vue');

const StaffTasks = () => import('@/entities/staff-tasks/staff-tasks.vue');
const StaffTasksUpdate = () => import('@/entities/staff-tasks/staff-tasks-update.vue');
const StaffTasksDetails = () => import('@/entities/staff-tasks/staff-tasks-details.vue');

const StaffTaskInstances = () => import('@/entities/staff-task-instances/staff-task-instances.vue');
const StaffTaskInstancesUpdate = () => import('@/entities/staff-task-instances/staff-task-instances-update.vue');
const StaffTaskInstancesDetails = () => import('@/entities/staff-task-instances/staff-task-instances-details.vue');

const WebpagesMembership = () => import('@/entities/webpages-membership/webpages-membership.vue');
const WebpagesMembershipUpdate = () => import('@/entities/webpages-membership/webpages-membership-update.vue');
const WebpagesMembershipDetails = () => import('@/entities/webpages-membership/webpages-membership-details.vue');

const WebpagesOAuthMembership = () => import('@/entities/webpages-o-auth-membership/webpages-o-auth-membership.vue');
const WebpagesOAuthMembershipUpdate = () => import('@/entities/webpages-o-auth-membership/webpages-o-auth-membership-update.vue');
const WebpagesOAuthMembershipDetails = () => import('@/entities/webpages-o-auth-membership/webpages-o-auth-membership-details.vue');

const WebpagesRoles = () => import('@/entities/webpages-roles/webpages-roles.vue');
const WebpagesRolesUpdate = () => import('@/entities/webpages-roles/webpages-roles-update.vue');
const WebpagesRolesDetails = () => import('@/entities/webpages-roles/webpages-roles-details.vue');

const WebpagesUsersInRoles = () => import('@/entities/webpages-users-in-roles/webpages-users-in-roles.vue');
const WebpagesUsersInRolesUpdate = () => import('@/entities/webpages-users-in-roles/webpages-users-in-roles-update.vue');
const WebpagesUsersInRolesDetails = () => import('@/entities/webpages-users-in-roles/webpages-users-in-roles-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'documents',
      name: 'Documents',
      component: Documents,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documents/new',
      name: 'DocumentsCreate',
      component: DocumentsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documents/:documentsId/edit',
      name: 'DocumentsEdit',
      component: DocumentsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/manage/:packagesId/stages/:stageId/documents/new',
      name: 'DocumentsNewCreate',
      component: DocumentsUpdateNew,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/manage/:packagesId/stages/:stageId/documents/:documentsId',
      name: 'DocumentsNewEdit',
      component: DocumentsUpdateNew,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documents/:documentsId/view',
      name: 'DocumentsView',
      component: DocumentsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages',
      name: 'Packages',
      component: Packages,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/manage',
      name: 'PackagesManage',
      component: PackagesManage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/manage/:packagesId',
      name: 'PackagesManageStages',
      component: PackagesManageStages,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/manage/:packagesId/stages/:stageId',
      name: 'PackagesManageStagesDocument',
      component: PackagesManageStagesDocument,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/new',
      name: 'PackagesCreate',
      component: PackagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/:packagesId/edit',
      name: 'PackagesEdit',
      component: PackagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packages/:packagesId/view',
      name: 'PackagesView',
      component: PackagesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentcategories',
      name: 'Documentcategories',
      component: Documentcategories,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentcategories/new',
      name: 'DocumentcategoriesCreate',
      component: DocumentcategoriesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentcategories/:documentcategoriesId/edit',
      name: 'DocumentcategoriesEdit',
      component: DocumentcategoriesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentcategories/:documentcategoriesId/view',
      name: 'DocumentcategoriesView',
      component: DocumentcategoriesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentinstances',
      name: 'Documentinstances',
      component: Documentinstances,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentinstances/new',
      name: 'DocumentinstancesCreate',
      component: DocumentinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentinstances/:documentinstancesId/edit',
      name: 'DocumentinstancesEdit',
      component: DocumentinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentinstances/:documentinstancesId/view',
      name: 'DocumentinstancesView',
      component: DocumentinstancesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentfields',
      name: 'Documentfields',
      component: Documentfields,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentfields/new',
      name: 'DocumentfieldsCreate',
      component: DocumentfieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentfields/:documentfieldsId/edit',
      name: 'DocumentfieldsEdit',
      component: DocumentfieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'documentfields/:documentfieldsId/view',
      name: 'DocumentfieldsView',
      component: DocumentfieldsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fields',
      name: 'Fields',
      component: Fields,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fields/new',
      name: 'FieldsCreate',
      component: FieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fields/:fieldsId/edit',
      name: 'FieldsEdit',
      component: FieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fields/:fieldsId/view',
      name: 'FieldsView',
      component: FieldsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stages',
      name: 'Stages',
      component: Stages,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stages/new',
      name: 'StagesCreate',
      component: StagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stages/:stagesId/edit',
      name: 'StagesEdit',
      component: StagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stages/:stagesId/view',
      name: 'StagesView',
      component: StagesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stageinstances',
      name: 'Stageinstances',
      component: Stageinstances,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stageinstances/new',
      name: 'StageinstancesCreate',
      component: StageinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stageinstances/:stageinstancesId/edit',
      name: 'StageinstancesEdit',
      component: StageinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stageinstances/:stageinstancesId/view',
      name: 'StageinstancesView',
      component: StageinstancesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packagestages',
      name: 'Packagestages',
      component: Packagestages,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packagestages/new',
      name: 'PackagestagesCreate',
      component: PackagestagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packagestages/:packagestagesId/edit',
      name: 'PackagestagesEdit',
      component: PackagestagesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packagestages/:packagestagesId/view',
      name: 'PackagestagesView',
      component: PackagestagesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packageinstances',
      name: 'Packageinstances',
      component: Packageinstances,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packageinstances-client',
      name: 'PackageinstancesClient',
      component: PackageinstancesClient,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packageinstances/new',
      name: 'PackageinstancesCreate',
      component: PackageinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packageinstances/:packageinstancesId/edit',
      name: 'PackageinstancesEdit',
      component: PackageinstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'packageinstances/:packageinstancesId/view',
      name: 'PackageinstancesView',
      component: PackageinstancesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'unicorn-users',
      name: 'UnicornUsers',
      component: UnicornUsers,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'unicorn-users/new',
      name: 'UnicornUsersCreate',
      component: UnicornUsersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'unicorn-users/:unicornUsersId/edit',
      name: 'UnicornUsersEdit',
      component: UnicornUsersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'unicorn-users/:unicornUsersId/view',
      name: 'UnicornUsersView',
      component: UnicornUsersDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-fields',
      name: 'ClientFields',
      component: ClientFields,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-fields/new',
      name: 'ClientFieldsCreate',
      component: ClientFieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-fields/:clientFieldsId/edit',
      name: 'ClientFieldsEdit',
      component: ClientFieldsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-fields/:clientFieldsId/view',
      name: 'ClientFieldsView',
      component: ClientFieldsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-notes',
      name: 'ClientNotes',
      component: ClientNotes,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-notes/new',
      name: 'ClientNotesCreate',
      component: ClientNotesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-notes/:clientNotesId/edit',
      name: 'ClientNotesEdit',
      component: ClientNotesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-notes/:clientNotesId/view',
      name: 'ClientNotesView',
      component: ClientNotesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-task-instances',
      name: 'ClientTaskInstances',
      component: ClientTaskInstances,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-task-instances/new',
      name: 'ClientTaskInstancesCreate',
      component: ClientTaskInstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-task-instances/:clientTaskInstancesId/edit',
      name: 'ClientTaskInstancesEdit',
      component: ClientTaskInstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-task-instances/:clientTaskInstancesId/view',
      name: 'ClientTaskInstancesView',
      component: ClientTaskInstancesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-tasks',
      name: 'ClientTasks',
      component: ClientTasks,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-tasks/new',
      name: 'ClientTasksCreate',
      component: ClientTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-tasks/:clientTasksId/edit',
      name: 'ClientTasksEdit',
      component: ClientTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'client-tasks/:clientTasksId/view',
      name: 'ClientTasksView',
      component: ClientTasksDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-client-tasks',
      name: 'CustomClientTasks',
      component: CustomClientTasks,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-client-tasks/new',
      name: 'CustomClientTasksCreate',
      component: CustomClientTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-client-tasks/:customClientTasksId/edit',
      name: 'CustomClientTasksEdit',
      component: CustomClientTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-client-tasks/:customClientTasksId/view',
      name: 'CustomClientTasksView',
      component: CustomClientTasksDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-staff-tasks',
      name: 'CustomStaffTasks',
      component: CustomStaffTasks,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-staff-tasks/new',
      name: 'CustomStaffTasksCreate',
      component: CustomStaffTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-staff-tasks/:customStaffTasksId/edit',
      name: 'CustomStaffTasksEdit',
      component: CustomStaffTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'custom-staff-tasks/:customStaffTasksId/view',
      name: 'CustomStaffTasksView',
      component: CustomStaffTasksDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'place-holders',
      name: 'PlaceHolders',
      component: PlaceHolders,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'place-holders/new',
      name: 'PlaceHoldersCreate',
      component: PlaceHoldersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'place-holders/:placeHoldersId/edit',
      name: 'PlaceHoldersEdit',
      component: PlaceHoldersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'place-holders/:placeHoldersId/view',
      name: 'PlaceHoldersView',
      component: PlaceHoldersDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-tasks',
      name: 'StaffTasks',
      component: StaffTasks,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-tasks/new',
      name: 'StaffTasksCreate',
      component: StaffTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-tasks/:staffTasksId/edit',
      name: 'StaffTasksEdit',
      component: StaffTasksUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-tasks/:staffTasksId/view',
      name: 'StaffTasksView',
      component: StaffTasksDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-task-instances',
      name: 'StaffTaskInstances',
      component: StaffTaskInstances,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-task-instances/new',
      name: 'StaffTaskInstancesCreate',
      component: StaffTaskInstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-task-instances/:staffTaskInstancesId/edit',
      name: 'StaffTaskInstancesEdit',
      component: StaffTaskInstancesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'staff-task-instances/:staffTaskInstancesId/view',
      name: 'StaffTaskInstancesView',
      component: StaffTaskInstancesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-membership',
      name: 'WebpagesMembership',
      component: WebpagesMembership,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-membership/new',
      name: 'WebpagesMembershipCreate',
      component: WebpagesMembershipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-membership/:webpagesMembershipId/edit',
      name: 'WebpagesMembershipEdit',
      component: WebpagesMembershipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-membership/:webpagesMembershipId/view',
      name: 'WebpagesMembershipView',
      component: WebpagesMembershipDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-o-auth-membership',
      name: 'WebpagesOAuthMembership',
      component: WebpagesOAuthMembership,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-o-auth-membership/new',
      name: 'WebpagesOAuthMembershipCreate',
      component: WebpagesOAuthMembershipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-o-auth-membership/:webpagesOAuthMembershipId/edit',
      name: 'WebpagesOAuthMembershipEdit',
      component: WebpagesOAuthMembershipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-o-auth-membership/:webpagesOAuthMembershipId/view',
      name: 'WebpagesOAuthMembershipView',
      component: WebpagesOAuthMembershipDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-roles',
      name: 'WebpagesRoles',
      component: WebpagesRoles,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-roles/new',
      name: 'WebpagesRolesCreate',
      component: WebpagesRolesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-roles/:webpagesRolesId/edit',
      name: 'WebpagesRolesEdit',
      component: WebpagesRolesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-roles/:webpagesRolesId/view',
      name: 'WebpagesRolesView',
      component: WebpagesRolesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-users-in-roles',
      name: 'WebpagesUsersInRoles',
      component: WebpagesUsersInRoles,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-users-in-roles/new',
      name: 'WebpagesUsersInRolesCreate',
      component: WebpagesUsersInRolesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-users-in-roles/:webpagesUsersInRolesId/edit',
      name: 'WebpagesUsersInRolesEdit',
      component: WebpagesUsersInRolesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'webpages-users-in-roles/:webpagesUsersInRolesId/view',
      name: 'WebpagesUsersInRolesView',
      component: WebpagesUsersInRolesDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
