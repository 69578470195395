var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
      _c("p", { staticStyle: { float: "left" } }, [
        _c(
          "a",
          {
            attrs: { target: "_blank", href: "http://vivacitycoaching.com.au" },
          },
          [
            _c("img", {
              attrs: {
                src: "/content/images/unicorn-footer.png",
                alt: "Unicorn logo",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { target: "_blank", href: "http://vivacitycoaching.com.au" },
          },
          [
            _c("img", {
              attrs: {
                src: "/content/images/vivacity-cape-footer.png",
                alt: "Vivacity Cape logo",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { float: "right" } }, [
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://www.vivacity.com.au" } },
          [
            _c("img", {
              attrs: {
                src: "/content/images/vivacity-footer.png",
                alt: "Vivacity logo",
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }